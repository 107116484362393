<template>
    <component :is="appointmentType[typeClinic]"></component>
</template>

<script>
import TableAppointment from './TableAppointment.vue';
import TableAppointmentInsurance from './facility-insurance/TableAppointmentInsurance.vue';

export default {
    components: {
        TableAppointment,
        TableAppointmentInsurance,
    },
    mounted(){
        const facilityInUse = this.$variableGlobal.isMedicalCenter;
        this.typeClinic = facilityInUse ? 2 : 1;
    },
    data(){
        return {
            typeClinic: null,
            appointmentType: {
                1: 'TableAppointment',
                2: 'TableAppointmentInsurance'
            }
        }
    },
    watch: {
        "$variableGlobal.isMedicalCenter": function (value) {
            this.typeClinic = value ? 2 : 1;
        },
    },
}
</script>