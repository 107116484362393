import { userAxios } from '../index'

const appointmentList = async (perPage) => {
    try {
        return await userAxios.get(`appointment/appointment-insurance-calculate/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentDetail = async (id) => {
    try {
        return await userAxios.get(`appointment/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentCreate = async (data) => {
    try {
        return await userAxios.post('appointment', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentUpdate = async (id, data) => {
    try {
        return await userAxios.put(`appointment/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentDelete = async (id) => {
    try {
        return await userAxios.delete(`appointment/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`appointment/appointment-insurance-calculate/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`appointment/appointment-insurance-calculate/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`appointment/appointment-insurance-calculate/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentPendingTreatment = async (patientId) => {
    try {
        return await userAxios.get(`appointment/validate-pending-procedures/${patientId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const checkoutProcedures = async (id) => {
    try {
        return await userAxios.put(`insurance-appointment/checkout-insurance-appointment/${id}`, {}).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    appointmentList,
    appointmentDetail,
    appointmentCreate,
    appointmentUpdate,
    appointmentDelete,
    appointmentPagination,
    appointmentFilter,
    appointmentFilterPagination,
    appointmentPendingTreatment,
    checkoutProcedures,
}